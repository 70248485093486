import React from 'react'
import styled from 'styled-components'
import WorkFlowFlex from '../ReusableComponent/WorkFlowFlex'
import { workFlows } from '../../Constants/WorkFlows'

const WorkFlowWrap = styled.section`
height: auto;
/* scroll-snap-align: start; */

`

const WorkFlow = () => {

  const workFlow1 = workFlows[0]
  const workFlow2 = workFlows[1]
  const workFlow3 = workFlows[2]
  const workFlow4 = workFlows[3]

  return (
    <WorkFlowWrap>
      <div className='container mx-auto px-2 sm:px-6 md:px-8'>
        <div className='font-normal md:font-semibold 
        text-white text-3xl md:text-4xl lg:text-5xl pt-6 
        leading-[1.4] md:p-2 md:mt-16'
        >Your projects, simplified.</div>

        <WorkFlowFlex image1={workFlow1.image1} image2={workFlow1.image2} title={workFlow1.title} statement={workFlow1.statement} firstWorkFlow={true} />
        <WorkFlowFlex image1={workFlow2.image1} title={workFlow2.title} statement={workFlow2.statement} />
        <WorkFlowFlex image1={workFlow3.image1} title={workFlow3.title} statement={workFlow3.statement} />
        <WorkFlowFlex image1={workFlow4.image1} image2={workFlow4.image2} title={workFlow4.title} statement={workFlow4.statement} />

      </div>
    </WorkFlowWrap>
  )
}

export default WorkFlow