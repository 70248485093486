import React, { useRef } from 'react'
import styled from 'styled-components'
import { keyFeatures } from '../../Constants/KeyFeauters'
import Card from '../ReusableComponent/Card'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive'
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

const OurKeyFeaturesWrap = styled.section`
height: auto;
/* scroll-snap-align: start; */
width: auto;

.slick-slider slick-initialized ul .slick-dots {
  position: relative;
  display: none;
}

.slick-dots li button:before {
    color: #5FCCF2 !important;
    position: absolute;
    translate: 0 40px;
}

@media screen and (max-width: 576px) {
  .slick-dots li button:before {
    translate: 0 50px;

}
}
`

function ArrowButton({ children, ...restOfTheProps }) {

  return (
    <button
      {...restOfTheProps}
    >
      {children}
    </button>
  );
}


const OurKeyFeatures = () => {

  const sliderRef = useRef();

  const isSmall = useMediaQuery({
    query: '(max-width: 676px)'
  })

  const isMedium = useMediaQuery({
    query: '(min-width: 676px) and (max-width: 1063px)'
  })


  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: isSmall ? 1 : isMedium ? 2 : 3,
    // slidesToScroll: isSmall ? 1 : isMedium ? 2 : 3,
    initialSlide: 1,
    // mobileFirst: true,
  }

  return (
    <OurKeyFeaturesWrap>
      <div className='font-black text-cyan text-3xl md:text-4xl lg:text-5xl pb-2'>Our key features</div>

      <div className='px-2 md:px-4'>



        <Slider {...settings} ref={sliderRef}>
          {keyFeatures.map((keyFeature) => {
            return (
              <div key={keyFeature.heading} className="flex justify-center items-baseline scale-75 sm:scale-90 md:scale-100">
                <Card image={keyFeature.image} heading={keyFeature.heading} statement={keyFeature.statement} />
              </div>
            )
          })}
        </Slider>
      </div>

      <div className='flex justify-around z-10 mt-4'>
        <ArrowButton className="" onClick={() => sliderRef.current.slickPrev()}>
          <div className='w-8 md:w-12 h-8 md:h-12 flex justify-center items-center rounded-[100%] bg-[#323943]'>
            <WestIcon style={{ color: "#5FCCF2" }} />
          </div>
        </ArrowButton>

        <ArrowButton className="" onClick={() => sliderRef.current.slickNext()}>
          <div className='w-8 md:w-12 h-8 md:h-12 flex justify-center items-center rounded-[100%] bg-[#323943]'>
            <EastIcon style={{ color: "#5FCCF2" }} />
          </div>
        </ArrowButton>
      </div>

    </OurKeyFeaturesWrap>
  )
}

export default OurKeyFeatures