import React from 'react'


const Card = ({ image, heading, statement }) => {
    return (
        <div className={`text-white p-0 sm:p-4 md:p-6 lg:p-12`}>
            <div className={`bg-[#333A44] w-80 max-w-sm h-80 max-h-sm m-0 px-6 sm:px-8 lg:px-12 py-10  
            inline-flex flex-col justify-center items-center align-baseline overflow-clip rounded-[100%]  relative`}>
                <img className='w-[100px] h-[100px]' src={image.src} alt={image.alt} width={100} height={100} />
                <div className='text-xl pt-2 px-2 whitespace-pre'>{heading}</div>
                <div className='text-sm font-thin p-1 sm:p-2 whitespace-pre-wrap'>{statement}</div>
            </div>
        </div>

    )
}

export default Card