import React from 'react'
// import eclipse from "../../Assets/Images/WorkFlow/Ellipse 658.svg"
import lineAnimation from '../../Assets/Images/WorkFlow/lf30_editor_8nwag63p.json'

const WorkFlowFlex = ({ image1, image2 = '', title, statement, firstWorkFlow }) => {
    return (
        <div className='mt-10 flex flex-col justify-center items-center'>

            <div className={``}>
                <div className={`text-2xl text-cyan font-black md:p-4`}>
                    {title}
                </div>

                <div className={`text-white mt-3 md:p-4`}>
                    {statement}
                </div>

            </div>


            {image1 && <div className={`mt-4 ${image2 && "place-self-start"}`}>
                {/* <img className='object-cover w-96 lg:w-[550px]' src={eclipse} alt="eclipse"></img> */}
                <img className='w-[350px] lg:w-[600px]' src={image1.src} alt={image1.alt} />
            </div>}


            <div className={`mt-4 ${image1 && firstWorkFlow ? "flex" : "place-self-end"}`}>

                {firstWorkFlow &&
                    <div className='hidden lg:block relative z-0'>
                        <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src={JSON.stringify(lineAnimation)}
                        ></lottie-player>
                    </div>
                }
                <img className='w-[450px] lg:w-[900px]' src={image2.src} alt={image2.alt} />

            </div>


        </div >
    )
}

export default WorkFlowFlex