import React from 'react'
import { Button2 } from './Button2'

const Plan = ({ tier, price, duration, title, benefits, buttontext, navigateTo, handleContactUsBtn }) => {


    return (
        <div className='flex flex-col max-w-[22rem] h-full border-2 border-cyan shadow-inner shadow-cyan rounded-md text-start p-4 relative '>

            <div className='font-medium text-3xl pt-2 pb-4'>
                {tier}
            </div>

            <div className='text-4xl text-cyan font-black pb-3 flex flex-col lg:flex-row lg:items-end gap-2'>
                ${price} <div className='text-white text-xl font-light'>USD</div>
                <div className='text-cyan text-sm ml-0 text-start lg:ml-auto font-semibold lg:text-end'>{duration}</div>
            </div>

            <div className='text-sm pb-4 '>
                {title}
            </div>

            <ul className='text-base'>
                {benefits?.map((benefit) => {
                    return <li key={benefit.id}>
                        {benefit.name}
                    </li>
                })}
            </ul>

            <div className='mb-4'>

            </div>

            <div className='flex justify-center md:justify-start mt-auto'>
                <a href={navigateTo}>
                    <Button2 styles={{ fontSize: "1.125rem" }} onClick={() => {
                        navigateTo === "#contactSales" && handleContactUsBtn()
                    }}>
                        {buttontext}
                    </Button2>
                </a>
            </div>


        </div>
    )
}

export default React.memo(Plan)