import './App.css';
import { Routes, Route } from "react-router-dom"
import Landing from './Components/Pages/Landing';
import Disclaimer from './Components/ReusableComponent/DisClaimer';


function App() {
  return (
    <div className="App">

      <Routes>

        <Route index path="/" element={<Landing />} />
        <Route path="/privacy-policy"
          element={<Disclaimer
            terms={false}
            privacy={true}
          />} />

        <Route path="/terms-&-conditions"
          element={<Disclaimer
            terms={true}
            privacy={false}
          />} />
      </Routes>
    </div>
  );
}

export default App;
