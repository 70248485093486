import React from 'react'
import styled from 'styled-components'
import trekr from "../../Assets/Images/Footer/Group 3946.svg"
import insta from "../../Assets/Images/Footer/insta.png"
import linkedIn from "../../Assets/Images/Footer/Group1305.e3a751fe.svg"
import twitter from "../../Assets/Images/Footer/twitter_new.jpeg"
import mailIcon from "../../Assets/Images/Footer/mail_white_24dp.svg"
import { Link } from 'react-router-dom'


const FooterWrap = styled.footer`

background-color: #0054D9;
height: auto;
color: white;
padding-bottom: 2px;


`

const Footer = ({ focusMail }) => {
  return (
    <FooterWrap>
      <div className='container mx-auto px-2 mt-2 pt-4 md:pt-12 lg:pt-16'>

        <div className='flex flex-row justify-between gap-2 items-center'>
          <img className='sm:pl-0' src={trekr} alt="tracTask-logo" width={120} />

          <div className='inline-flex  gap-2 items-center justify-center'>
            <a href="mailto:info@tractask.com" className={`inline-block hover:underline underline-offset-2 ${focusMail && "animate-pulse hover:animate-none"}`} id="contactSales">
              <img className='inline-block' src={mailIcon} alt="mail" width="20" />
              {` info@tractask.com`}
            </a>
          </div>

        </div>

        <div className='flex sm:flex-row flex-col sm:justify-between flex-wrap mt-4 mb-4'>
          <div className='flex gap-x-6 md:gap-x-8 lg:gap-x-16 gap-y-2 flex-row justify-center md:flex-wrap flex-nowrap'>
            <Link to="/terms-&-conditions">
              <div className=' pt-2 text-center md:text-left cursor-pointer hover:underline underline-offset-2'>Terms and conditions</div>
            </Link>
            <Link to="/privacy-policy">
              <div className=' pt-2 text-center md:text-left cursor-pointer hover:underline underline-offset-2'>Privacy policy</div>
            </Link>
          </div>
          <div className='flex gap-6 items-baseline justify-center mr-0 md:mr-8 mt-4 sm:mt-0'>
            <a href= "https://www.linkedin.com/company/waila-technology/" target='_blank' rel="noopener noreferrer">
            <div className='text-left hover:scale-105 transition-all ease-in-out'>
              <img src={linkedIn} alt="linkedIn" width="40px" />
            </div>
            </a>
            <a href='https://twitter.com/wailatech' target='_blank'>
            <div className='text-left hover:scale-105 transition-all ease-in-out mb-2' >
              <img src={twitter} alt="twitter" width="29px" height="0px"  />
            </div>
            </a>
          </div>

        </div>
      </div>
    </FooterWrap>
  )
}

export default Footer