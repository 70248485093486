import React from 'react'

export const Button = ({ children, type = "text", className, ...restProps }) => {
    return (
        <button
            {...restProps}
            type={type}
            className={
                `${className} font-bold border disabled:opacity-40
             border-cyan px-10 py-2 transition ease-in-out hover:transition-all hover:scale-105 
             rounded-[5px]
            ${restProps.variant === "fill" ? "bg-white text-blue" : "text-white"}`}>{children}
        </button>
    )
}

