import React, { useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
// import CreateAccount from './CreateAccount'
// import CustomerSupport from './CustomerSupport'
import Footer from './Footer'
import GetStarted from './GetStarted'
import HomeScreen from './HomeScreen'
import OurKeyFeatures from './OurKeyFeatures'
import WorkFlow from './WorkFlow'
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import PricingPlans from './PricingPlans'

const LandingWrapper = styled.div`
width: auto;
height: 100vh;
/* scroll-snap-type: y proximity; */
background-color: #1C242F;
color: #FFFFFF;
scroll-behavior: smooth;
overflow-y: auto;
`

const ScrollToTop = styled.div`

`

const Landing = () => {

    const [focusMail, setFocuMail] = useState(false)

    const { ref: scrollRef, inView } = useInView({
        threshold: 0,
    })

    const homePageRef = useRef()
    const pricingRef = useRef()

    const scrollToTop = () => {
        homePageRef.current.scrollIntoView({
            behavior: "smooth"
        })
    }

    const goToTrekrSystemLogin = () => {
        return 'https://app.tractask.com/login'
    }

    const goToTrekrSystemRegister = () => {
        return 'https://app.tractask.com/register'
    }


    const scrollToPricingRef = () => {
        pricingRef.current.scrollIntoView({
            behavior: "smooth"
        })
    }

    const handleContactUsBtn = () => {
        setFocuMail(true)
        return "#"
    }

    return (
        <LandingWrapper>

            <HomeScreen
                homePageRef={homePageRef}
                scrollToPricingRef={scrollToPricingRef}
                goToTrekrSystemLogin={goToTrekrSystemLogin}
                goToTrekrSystemRegister={goToTrekrSystemRegister} />

            <ScrollToTop ref={scrollRef}>

                {/* //*ScrollToTop Button */}
                {inView && <div className='absolute opacity-60 hover:opacity-80 bottom-0 right-0 scale-50 z-50 cursor-pointer 
                transition ease-in-out  hover:-translate-y-1  duration-300' onClick={() => { scrollToTop() }}>
                    <ArrowDropDownCircleIcon className='rotate-180' sx={{ fontSize: "100px", color: "#5FCCF2" }} />
                </div>}

                <div className='z-10'>
                    <OurKeyFeatures />
                    <WorkFlow />
                    <PricingPlans pricingRef={pricingRef} handleContactUsBtn={handleContactUsBtn} />
                    {/* <CreateAccount  /> */}
                    {/* <CustomerSupport  /> */}
                    <GetStarted goToTrekrSystemRegister={goToTrekrSystemRegister} />
                    <Footer focusMail={focusMail} />
                </div>
            </ScrollToTop>

        </LandingWrapper>
    )
}

export default Landing