import React from 'react'
import styled from 'styled-components'
import { Button } from '../ReusableComponent/Button'

const GetStartedWrap = styled.div`
--light-blue: #5FCCF2;
--dark-blue: #0054D9;
height: auto;
background: transparent linear-gradient(180deg, var(--light-blue) 0%, var(--dark-blue) 100%) 0% 0% no-repeat padding-box;
/* scroll-snap-align: start; */
color: white;

`

const GetStarted = ({ goToTrekrSystemRegister }) => {
  return (
    <GetStartedWrap>
      <div className=''>
        <div className='container mx-auto px-4'>
          <div className='pt-12 md:pt-20  text-4xl md:text-5xl font-normal'>
            Deliver your best work <span className='font-black'>with tracTask</span>
          </div>

          <div className='pt-8 text-2xl md:text-3xl font-light'>15 days free trial | No credit card needed</div>

          <div className='mt-16 pb-12 md:pb-16 text-center'>
            <a href={goToTrekrSystemRegister()}>
              <Button variant="fill">
                Get Started
              </Button>
            </a>
          </div>
        </div>
      </div>
    </GetStartedWrap>
  )
}

export default GetStarted