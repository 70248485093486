import React from 'react'

export const Button2 = ({ children, type = "text", className, ...restProps }) => {
    return (
        <button
            {...restProps}
            type={type}
            className={
                `${className} font-bold px-10 py-2 disabled:opacity-40 
                transition ease-in-out hover:transition-all hover:scale-105 
             rounded-[5px]
            ${restProps.variant === "fill" ? "bg-blue text-white" : "text-blue bg-cyan"}`}>{children}
        </button>
    )
}

